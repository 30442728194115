<template>
  <div class="d-flex country-code-phone-input">
    <v-autocomplete
      v-model="country"
      :items="countries"
      hide-details
      item-value="phone_code"
      :item-text="selectedCountryDisplayName"
      return-object
      :loading="isLoading"
      hide-no-data
      class="rounded-0"
      cache-items
      v-bind="autocompleteAttrs"
      @change="codePhoneChange"
    >
      <template v-slot:item="{ item }">
        <v-img
          max-width="25"
          max-height="25"
          :src="requireFlag(item.flag)"
          eager
        >
          <template v-slot:placeholder>
            <v-img width="25" height="25" :src="getCompanyLogoURL(true)" />
          </template>
        </v-img>
        <span class="ml-2 mr-2">
          {{ item.iso3 }}
          ( {{ item.phone_code }} )
        </span>
      </template>
    </v-autocomplete>
    <v-text-field v-model="phone" v-bind="fieldAttrs" @change="phoneChange">
    </v-text-field>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import AsyncImage from "../../components/Util/AsyncImage";
export default {
  name: "CountryCodePhoneInput",
  components: {
    AsyncImage,
  },
  props: {
    fieldAttrs: {
      type: Object,
      required: false,
    },
    autocompleteAttrs: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      country: null,
      isLoading: false,
      phone: null,
    };
  },
  computed: {
    ...mapGetters({
      countries: "countries/getCountries",
    }),
  },
  methods: {
    selectedCountryDisplayName(country) {
      return country.iso3 + " (" + country.phone_code + ")";
    },
    phoneChange(val) {
      //let phone = { country_code: this.country.phone_code, phone: val };
      this.$emit("phone-changed", val);
    },
    codePhoneChange(val) {
      //let phone = { country_code: this.country.phone_code, phone: val };
      this.$emit("code-phone-changed", val.phone_code);
    },
    fetchUserLocation() {

      // let apiEndpoint = "https://api.ipbase.com/v2/info?apikey=6huaS7ui97Wb2oFPouIVRnw9wFdWjbB33mflhuNh";
      let apiEndpoint = "https://api.ipdata.co?api-key=e6d1d46da1351734be41848ffbe98f714a20c82f988001c38fcca87f";
      
      this.$http
        .get(apiEndpoint)
        .then((location) => {
          this.country = this.countries.filter((country) => {
            return country.iso2 == location.data.country_code;
          })[0];
          this.$emit("code-phone-changed", this.country.phone_code);
        });
    },
    requireFlag(flag) {
      try {
        return require("../../assets/" + flag);
      } catch (e) {}
    },
  },
  created() {    
    this.$store.dispatch("countries/get").then(resp => {
      if (!this.autocompleteAttrs.value) {
        this.fetchUserLocation();
      }
    });
  },
  mounted() {
    if (this.autocompleteAttrs.value) {
      this.country = this.autocompleteAttrs.value;
    } else {
      this.country = this.countries[0];
    }

    if (this.fieldAttrs.value) {
      this.phone = this.fieldAttrs.value;
    }
  },
};
</script>

<style lang="scss">
.v-application--is-rtl {
  .country-code-phone-input.v-text-field.v-text-field--enclosed
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
    padding-left: 12px;
  }
}
.country-code-phone-input.v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding-left: 0;
  padding-right: 12px;
}
</style>
<style scoped lang="scss">
.country-code-phone-input {
  .v-select {
    margin: 0;
    width: 125px;
  }
}
</style>